//@ts-check
const path = require('path');

/**
 * Force certain start and end chars
 * @param {string} str
 * @param {string} char
 * @param {boolean} [atStart]
 * @param {boolean} [atEnd]
 */
function forceBoundsChars(str, char, atStart, atEnd) {
	char = char.charAt(0);

	if (atStart) {
		str = str.startsWith(char) ? str : `${char}${str}`;
	} else if (atStart === false) {
		str = str.startsWith(char) ? str.substr(1) : str;
	}

	if (atEnd) {
		str = str.endsWith(char) ? str : `${str}${char}`;
	} else if (atEnd === false) {
		str = str.endsWith(char) ? str.substr(0, str.length - 1) : str;
	}

	return str;
}

/**
 * Force start and/or end slash
 * @param {string} str
 * @param {boolean} [atStart]
 * @param {boolean} [atEnd]
 */
function forceBoundsSlashes(str, atStart, atEnd) {
	return forceBoundsChars(str, '/', atStart, atEnd);
}

/**
 * Force leading slash ('/')
 * @param {string} str
 */
function forceLeadingSlash(str) {
	return forceBoundsSlashes(str, true, null);
}

function filenameToDisplayName(filename) {
	// Strip off extension
	if (/[^.]{2,}\..{1,}/.test(filename)) {
		filename = filename.replace(/\..{1,}$/, '');
	}
	// Replace "-" or "_" with " "
	filename = filename.replace(/[-_]/g, ' ');
	// Proper Case
	return toTitleCase(filename);
}

function slugToDisplayName(slug) {
	slug = slug.replace(/-/gim, ' ');
	slug = slug
		.split(/\//)
		.map(e => {
			return toTitleCase(e);
		})
		.join('/');
	return slug;
}

/**
 * @param {string} e
 */
function toTitleCase(e) {
	/** @type {string[]} */
	const words = e
		.toLowerCase()
		.split(' ')
		.map(word => {
			// Edge-case: If word is ALLCAPS, leave alone
			if (word === word.toUpperCase()) {
				return word;
			}

			return word.charAt(0).toUpperCase() + word.slice(1);
		});
	return words.join(' ');
}

/**
 * Implement Gatsby's Node APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/node-apis/
 */

function getTimestamp(dateString, useMs, fallback) {
	if (typeof dateString === 'string') {
		let date = new Date(dateString);
		let stamp = date.getTime() / (useMs ? 1 : 1000);
		if (!Number.isNaN(stamp)) {
			return Math.floor(stamp);
		}
	}
	return fallback;
}

/**
 * Normalizes and forces a filepath to the forward slash variant
 * Example: \dir\file.txt will become /dir/file.txt
 * @param {string} filePath the path to normalize
 */
function posixNormalize(filePath) {
	return path.normalize(filePath).replace(/[\/\\]{1,2}/gm, '/');
}

/**
 * There is probably a better way to do this...
 * Try to replace relative MD links like cheatsheets-and-misc/md/cheatsheets/js/js-misc.md
 * @param {string} rawHtml - raw HTML
 * @typedef {Object<string,any>} mdNode
 * @param {mdNode[]} allMdRemarkNodes - Make sure you pass in nodes that have both fileAbsolutePath and fields.slug attached.
 */
function linkFixer(rawHtml, allMdRemarkNodes) {
	let relLinkPatt = /(<a[^>]*href=")([^"]*\/[^"]+)"/gim;
	// Search for relative links and replace
	let formattedHtml = rawHtml.replace(relLinkPatt, function (match, g1, g2, offset, input) {
		/** @type {string} */
		let hrefVal = g2;
		// Don't do anything for actual links
		if (!/^https{0,1}|^\/\//.test(hrefVal)) {
			// Special - remove `^md/...` prefix, non-relative
			hrefVal = hrefVal.replace(/^md\//, '');
			// Special - remove /md/ prefix dir, anywhere in chain (catches relative paths, with `../../md/..`)
			hrefVal = hrefVal.replace(/^.*\/md\//, '');
			// Special - remove relative prefix of link - e.g. `.` out of `./sub/...`
			// Also, `../` (or any number, e.g. `/../../`, `../.../`, etc)
			// This is because later on `.` will be escaped (for RegEx) and will not allow partial matches if left
			// We need to be able to match `./sub/file.md` to `c:/sub/file.md`, etc.
			// https://regexr.com/5fn02
			hrefVal = hrefVal.replace(/^\.*(?:\/\.\.)*/, '');
			// Special - break apart and capture hash/anchor link - e.g. /dir/nato.md#bravo
			let anchor;
			[hrefVal, anchor] = hrefVal.split('#');
			// Compose regex pattern - e.g. /cheatsheets\/js\/js-misc.md$/
			// All characters that have special meaning in regex need to be "escaped". E.g. `foo/bar` needs to be come `foo\/bar`
			// https://stackoverflow.com/a/3561711/11447682
			let partialHrefPatt = hrefVal.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
			// Special: We need to catch paths where there is an upwards traversal in the middle, like `/md/../file.md`
			// At this point, RegEx chars have been escaped and will not be escaped again, so we can replace the problem sections with wildcard matching
			// there is no good way to know (without traversing) what can be collapsed and removed and still lead to a partial fullpath match later...
			// as a last resort, we can just remove the entire leading section if it contains it
			// https://regexr.com/5fn2s
			partialHrefPatt = partialHrefPatt.replace(/^.*\\\/\\\.\\\.\\\//, '');
			// Add unescaped $ at end, to prevent multiple partial matches
			let hrefPatt = new RegExp(partialHrefPatt + '$');
			// Lookup within allMarkdownRemark records
			for (let x = 0; x < allMdRemarkNodes.length; x++) {
				if (hrefPatt.test(allMdRemarkNodes[x].fileAbsolutePath)) {
					// Replace href with the slug
					hrefVal = `${allMdRemarkNodes[x].fields.slug}${!!anchor ? `#${anchor}` : ''}`;
					break;
				}
			}
		}
		return g1 + hrefVal + '"';
	});

	return formattedHtml;
}

/**
 * Get name of directory (folder name)
 * @param {string} dirAbsPath Directory path
 * @returns {string} Directory name
 */
function getDirectoryName(dirAbsPath) {
	return path.posix.basename(dirAbsPath);
}

/**
 * Get relative path of file or directory
 * @param {string} absPath The absolute path
 * @returns {string} The relative path
 */
function getRelativepath(absPath) {
	return posixNormalize(absPath).replace(projectRootPath, '');
}

/**
 * Get / generate a title for a MarkdownRemark node
 * @param {object} mdNode Either MdRemarkNode or MarkdownNode
 * @param {import('../gatsby-types').FileNode} fileNode
 * @returns {string} Title
 */
function getMdRemarkNodeTitle(mdNode, fileNode) {
	let title = filenameToDisplayName(fileNode.name);

	if (mdNode.frontmatter && !!mdNode.frontmatter.title) {
		title = mdNode.frontmatter.title;
	}

	return title;
}

const fileMethods = {
	posixNormalize,
	getDirectoryName,
	getRelativepath,
	getMdRemarkNodeTitle,
};

const projectRootPath = posixNormalize(path.normalize(__dirname + '../../../'));

const getIsDebug = () => {
	if (process.env.NODE_ENV === 'development') {
		return true;
	}

	if (typeof window === 'object' && window.location.hostname === 'localhost') {
		return true;
	}

	return false;
};

/**
 * Merge an object with `window.debugData` for easier debugging
 * @param {object} obj
 */
const mergeWithWindowDebug = obj => {
	if (getIsDebug()) {
		/** @type {*} */
		const windowAsAny = window;
		windowAsAny.debugData = typeof windowAsAny.debugData === 'object' ? windowAsAny.debugData : {};
		Object.assign(windowAsAny.debugData, obj);
	}
};

/**
 * Get the vertical offset necessary so fixed top buttons don't overlap with content
 */
const getFixedYOffset = () => {
	const offsetElem = document.querySelector('.mainMenuToggle');
	let offsetHeight;
	try {
		const bounds = offsetElem.getBoundingClientRect();
		offsetHeight = bounds.bottom + 4;
	} catch (e) {
		offsetHeight = 62;
	}
	return offsetHeight;
};

/**
 * Get the position (offset) of an element
 * @param {Element} element
 */
const getElementPosition = element => {
	const bounds = element.getBoundingClientRect();
	return {
		top: bounds.top + window.pageYOffset,
		left: bounds.left + window.pageXOffset,
	};
};

/**
 * Scroll to an element, with necessary offset
 * @param {Element} element
 */
const scrollToElement = element => {
	if (typeof window !== 'object') {
		return false;
	}

	const yCoord = getElementPosition(element).top - getFixedYOffset();
	window.scrollTo(window.scrollX, yCoord);
	return true;
};

/**
 *
 * @param {string} hash
 */
const scrollToHash = hash => {
	if (!hash || typeof hash !== 'string') {
		return false;
	}
	hash = hash.replace('#', '');
	const targetElem = document.getElementById(hash);
	if (!targetElem) {
		return false;
	}

	return scrollToElement(targetElem);
};

/**
 *
 * @param {number} xCoord
 * @param {number} yCoord
 */
const scrollSmooth = (xCoord, yCoord) => {
	if (window) {
		try {
			window.scrollTo({
				top: yCoord,
				left: xCoord,
				behavior: 'smooth',
			});
		} catch (e) {
			window.scrollTo(xCoord, yCoord);
		}
	}
};

const scrollToTop = () => {
	if (window) {
		const { hash, href } = window.location;
		window.scrollTo(window.scrollX, 0);
		if (hash && window.history && window.history.pushState) {
			const cleanUrl = href.replace(hash, '');
			window.history.pushState({}, null, cleanUrl);
		} else {
			setTimeout(() => {
				if (hash) {
					window.location.hash = '';
				}
			}, 100);
		}
	}
};

/**
 * @param {Array<string | undefined | null>} _classNames
 */
const classNames = _classNames => {
	return _classNames.filter(c => !!c).join(' ');
};

const domMethods = {
	getFixedYOffset,
	getElementPosition,
	scrollToElement,
	scrollToTop,
	scrollToHash,
	scrollSmooth,
};

const helpers = {
	strMethods: {
		forceBoundsChars,
		forceBoundsSlashes,
		forceLeadingSlash,
		filenameToDisplayName,
		slugToDisplayName,
		toTitleCase,
	},
	getTimestamp,
	fileMethods,
	linkFixer,
	projectRootPath,
	getIsDebug,
	mergeWithWindowDebug,
	domMethods,
	classNames,
};

module.exports = helpers;
