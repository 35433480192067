/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
const { domMethods, getIsDebug } = require('./src/helpers');

/**
 * This is called on BOTH page navigation, and location changes (e.g. hash link click)
 * @param {import('gatsby').ShouldUpdateScrollArgs} cbArgs
 * @param {{object}} pluginOptions
 * @returns {boolean | string | Array<number>}
 * @see https://www.gatsbyjs.org/docs/browser-apis/#shouldUpdateScroll
 * @see https://github.com/gatsbyjs/gatsby/issues/141
 * @see https://github.com/gatsbyjs/gatsby/issues/1719
 * @see https://github.com/gatsbyjs/gatsby/issues/19488
 */
exports.shouldUpdateScroll = (cbArgs, pluginOptions) => {
	const { location } = cbArgs.routerProps;
	if (getIsDebug()) {
		console.log('shouldUpdateScroll called!', location);
	}
	if (!!location.hash) {
		// Need to return inverse, since returning false is what prevents default Gatsby scroll jump, and scrollToHash returns true if it found element and handled scroll with success
		return !domMethods.scrollToHash(location.hash);
	} else {
		return true;
	}
};

/**
 * Called when user changes route - e.g. internal link click
 * @param {import('gatsby').RouteUpdateArgs} cbArgs
 * @see https://www.gatsbyjs.org/docs/browser-apis/#onRouteUpdate
 */
exports.onRouteUpdate = cbArgs => {
	if (getIsDebug()) {
		console.log('onRouteUpdate called!');
	}
	const { hash } = cbArgs.location;
	if (!!hash) {
		setTimeout(() => domMethods.scrollToHash(hash), 5);
	}
};
